import { useSaveAdLoadSession } from "shared/hooks/adLibrary/adLoad/adLoadSessions";
import uuid from "uuid";
import { useAds } from "../../shared/hooks/useAds";
import { useCallback } from "react";
import moment from "moment";
import { useSessionParam } from "./useCurrentSession";

export const useCreateAdLoadSession = () => {
  const { selectedItems: selectedAds, isLoading: isLoadingAds } = useAds();

  const { setSessionId } = useSessionParam();

  const { mutateAsync: saveSession, isLoading: isCreatingAdLoadSession } =
    useSaveAdLoadSession();

  const createAdLoadSession = useCallback(async () => {
    const name = `${selectedAds?.length ?? 0} Ads - ${moment().format(
      "MMM DD",
    )}`;

    const session = await saveSession({
      id: uuid(),
      name,
      data: {
        step: "select",
        adIdsToLoad: selectedAds.map(ad => ad.id),
        adProtoDrafts: [],
        selectedStoreNames: [],
      },
    });

    if (session) {
      setSessionId(session.id);
    }
  }, [saveSession, selectedAds, setSessionId]);

  return {
    createAdLoadSession,
    isCreatingAdLoadSession,
    isLoadingAds,
  };
};
