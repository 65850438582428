import { Button, Result, Divider, notification, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  IAssetBuilderState,
  IExportForBatchToS3Response,
  RawSelectedOffers,
} from "shared/types/assetBuilder";
import actions from "../redux/rootActions";

import "./AssetBuilder.scss";
import Header from "shared/components/Header";
import { IHeader } from "shared/types/header";
import Review from "./assetBuilder/offers/Review";
import RenderTemplateProvider from "shared/components/RenderTemplateProvider";
import { AssetExportQueryParams } from "shared/types/assetExport";
import { IUploadImagesResult } from "shared/types/uploadManagement";
import { IDataTableError } from "shared/types/errors";
import API from "services";
import { IConfig, IConfigurationState } from "shared/types/configuration";

interface IAssetExport {
  disableExport: boolean;
  savedOrder: IAssetBuilderState["savedOrder"];
  rawSelectedOffers: RawSelectedOffers;
  saveDialog: boolean;
  uploadError: IDataTableError | null;
  uploadResult: IUploadImagesResult | null;
  fetchFeedOrder: (queryParams: AssetExportQueryParams) => void;
  fetchOffersFromFeedIndex: (feedIndex: string) => void;
  config?: IConfig;
}

const AssetExport: FC<IAssetExport> = ({
  disableExport,
  savedOrder,
  fetchFeedOrder,
  fetchOffersFromFeedIndex,
  config,
}) => {
  const [willFireBatchUpload, setWillFireBatchUpload] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  // This will be passed to ReviewPage.tsx for toggling export modal
  const [showCoopModal, toggleCoopModal] = useState(false);
  const [showExportDrawer, toggleExportDrawer] = useState(false);
  const [forceRenderImages, toggleForceRenderImages] = useState(false);

  const { search } = useLocation();
  const queryParams = Object.fromEntries(
    new URLSearchParams(search),
  ) as AssetExportQueryParams;

  useEffect(() => {
    const { assetType, feedId, id, idxStart, idxEnd } = queryParams;
    if (!search || !feedId || !idxStart || !idxEnd || !id || !assetType) return;

    fetchFeedOrder(queryParams);
  }, [fetchFeedOrder, queryParams, search]);

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: "35em",
    },
    topSteps: {
      selected: "Export Assets for Batch",
      style: {},
      steps: [{ title: "Export Assets for Batch", state: "enabled" }],
    },
    actionButtons: [
      <Button
        key="build-asset-action-button"
        id="export-btn"
        className="asset-builder action-button"
        disabled={!savedOrder || willFireBatchUpload}
        style={!savedOrder ? { backgroundColor: "#fcaf77", opacity: 0.8 } : {}}
        onClick={() => {
          setUploadComplete(false);
          setWillFireBatchUpload(true);
        }}
      >
        Export to S3
      </Button>,
      <Button
        key="build-asset-test-button"
        id="test-btn"
        className="asset-builder action-button"
        disabled={!savedOrder || willFireBatchUpload}
        style={!savedOrder ? { backgroundColor: "#fcaf77", opacity: 0.8 } : {}}
        onClick={async () => {
          if (!config) {
            return;
          }
          const { result, error } =
            await API.privServices.assetBuilder.exportForBatchToS3<IExportForBatchToS3Response>(
              queryParams,
            );

          if (result) {
            notification.success({
              message: "Success",
              description: "The assets were uploaded to S3",
              placement: "bottomRight",
            });
          } else {
            notification.error({
              message: "Error",
              description: error?.message || "Something went wrong",
              placement: "bottomRight",
            });
          }
        }}
      >
        Test Puppeteer
      </Button>,
      <Button
        key="build-asset-fetch-button"
        id="fetch-btn"
        className="asset-builder action-button"
        disabled={!savedOrder || willFireBatchUpload}
        style={!savedOrder ? { backgroundColor: "#fcaf77", opacity: 0.8 } : {}}
        onClick={() => {
          /*
            With some alteration of the getOfferList service in the API,
            this can help invoke the composeOrder function. It will
            generate the assetInstances and selectedOffers JSON files
            needed to mimic the order state.
          */
          fetchOffersFromFeedIndex("av2-nu-feed-stream-stg-data");
        }}
      >
        NU Index Fetch
      </Button>,
    ],
  };

  return (
    <div className="asset-builder-container">
      <Header
        style={headerMenus.style}
        topSteps={headerMenus.topSteps}
        actionButtons={headerMenus.actionButtons}
      />
      {!disableExport && !uploadComplete && (
        <span style={{ opacity: 0 }} className="export-ready-span">
          Export is ready!
        </span>
      )}
      {uploadComplete && (
        <span style={{ opacity: 0 }} className="upload-complete-span">
          Upload complete!
        </span>
      )}

      {!savedOrder && (
        <div>
          <Divider style={{ opacity: 0 }} />
          <Divider style={{ opacity: 0 }} />
          <Result
            status={queryParams?.feedId ? "info" : "404"}
            title={queryParams?.feedId ? "Loading..." : "404"}
            subTitle={
              queryParams?.feedId
                ? "Fetching asset data..."
                : "No Asset batch could be found."
            }
          />
        </div>
      )}

      {savedOrder && (
        <Spin spinning={willFireBatchUpload} tip="Exporting Assets to S3...">
          <RenderTemplateProvider>
            <Review
              willFireBatchUpload={willFireBatchUpload}
              showCoopModal={showCoopModal}
              toggleCoopModal={toggleCoopModal}
              showExportDrawer={showExportDrawer}
              toggleExportDrawer={toggleExportDrawer}
              forceRenderImages={forceRenderImages}
              toggleForceRenderImages={toggleForceRenderImages}
              fetchOrderState={() => {
                if (!queryParams?.feedId) {
                  notification.error({
                    message: "Could not get batch data",
                    description: "No batch id was provided in the URL",
                    placement: "bottomRight",
                  });
                  return;
                }
                fetchFeedOrder(queryParams);
              }}
              toggleWillBatchUpload={(value: boolean) => {
                if (!value) {
                  setUploadComplete(true);
                }
                setWillFireBatchUpload(value);
              }}
            />
          </RenderTemplateProvider>
        </Spin>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { uploadManagement } = state;
  const {
    assetBuilder,
    configuration: { config },
  } = state as {
    assetBuilder: IAssetBuilderState;
    configuration: IConfigurationState;
  };

  const { savedOrder, disableExport, rawSelectedOffers, saveDialog } =
    assetBuilder;

  const { result: uploadResult, error: uploadError } = uploadManagement;

  return {
    disableExport,
    savedOrder,
    config,
    rawSelectedOffers,
    saveDialog,
    uploadResult,
    uploadError,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchOffersFromFeedIndex: (feedIndex: string) => {
      dispatch(actions.assetBuilder.fetchOfferList({ feedIndex }));
    },
    fetchFeedOrder: (queryParams: AssetExportQueryParams) => {
      dispatch(actions.assetBuilder.fetchFeedOrder(queryParams));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetExport);
