import { PropsWithChildren } from "react";
import styles from "./FilterByInput.module.scss";

type FilterByProps = {
  title: string;
};

export const FilterBy = ({
  title,
  children,
}: PropsWithChildren<FilterByProps>) => {
  return (
    <div className={styles.filterBy}>
      <div className={styles.title}>{title}</div>
      <div className={styles.options}>{children}</div>
    </div>
  );
};
