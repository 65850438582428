import { useEffect } from "react";
import { assetBatchExportNotification } from "screens/assetExporter/feedConfigurationV2/ProcessNotification";
import { createLinkAndRoute } from "screens/assetExporter/sourceFeed/utils";
import { useAssetBatchesExportRequests } from "./useExportAssetBatches";
import { useExportExecutionArns } from "./useExportExecutionArns";

export function useAssetBatchExportNotification() {
  const { exportExecutionArns, handleSetExportExecutionArns } =
    useExportExecutionArns();

  const assetBatchRequests = useAssetBatchesExportRequests(exportExecutionArns);

  useEffect(() => {
    assetBatchRequests.forEach(({ data: job }) => {
      if (!job) return;

      assetBatchExportNotification({
        getCsvDownloadUrl: () => {
          const { url } = job.responseJson.outputs.filter(
            output => output.type === "csv",
          )?.[0];
          if (url) createLinkAndRoute(url);
        },
        notificationKey: job.executionId,
        onClose: () => {
          handleSetExportExecutionArns(
            exportExecutionArns.filter(arn => arn !== job.executionId),
          );
        },
        message: job.description,
        status: job.status,
        percentage: job.responseJson?.percentage ?? 0,
        exportFor: ["CSV download"],
        onAdLibClick: () => {
          return;
        },
      });
    });
  }, [assetBatchRequests, handleSetExportExecutionArns, exportExecutionArns]);
}
