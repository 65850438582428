import { useCallback } from "react";
import { useStartExportAssetBatches } from "./useExportAssetBatches";
import { useExportExecutionArns } from "./useExportExecutionArns";
import { ExportBatchAssetsBody } from "shared/types/assetExporter";
import { User } from "redux/auth/auth.slice";

export type GetExportAssetBatchesBody = {
  selectedRows: string[];
  selectedAssetBatches: string[];
  feedId: string;
  user?: User | null;
};

export const getExportAssetBatchesBody = ({
  selectedRows,
  selectedAssetBatches,
  feedId,
  user,
}: GetExportAssetBatchesBody): ExportBatchAssetsBody => {
  return {
    feedId,
    createdBy: user?.email ?? "",
    selectedRows: selectedRows,
    assetBatchIds: selectedAssetBatches,
  };
};

export const useAssetBatchExportGeneration = ({
  feedId,
  user,
  selectedRows,
  selectedAssetBatches,
}: GetExportAssetBatchesBody) => {
  const { mutate: exportAssetBatches, isLoading: isStartingExport } =
    useStartExportAssetBatches();

  const { exportExecutionArns, handleSetExportExecutionArns } =
    useExportExecutionArns();

  const handleStartExportAssetBatches = useCallback(
    ({ onSettled }: { onSettled?: () => void }) => {
      const body = getExportAssetBatchesBody({
        feedId,
        user,
        selectedRows,
        selectedAssetBatches,
      });

      exportAssetBatches(body, {
        onSuccess: data => {
          if (!data) return;
          handleSetExportExecutionArns(
            exportExecutionArns.concat(data.executionArn),
          );
        },
        onSettled: () => onSettled?.(),
      });
    },
    [
      exportAssetBatches,
      exportExecutionArns,
      feedId,
      handleSetExportExecutionArns,
      selectedAssetBatches,
      selectedRows,
      user,
    ],
  );

  return {
    isStartingExport,
    handleStartExportAssetBatches,
  };
};
