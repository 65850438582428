import { Spin } from "antd";
import { ITableColumn, useDataList } from "shared/components/dataList";
import VirtualTable from "shared/components/VirtualTable";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import useResizableTable from "shared/hooks/useResizableTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { FeedTblColWithRender, FeedTblRow } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import styles from "./Preview.module.scss";

type Props = {
  feedTableColumns: FeedTblColWithRender[];
};

const getTitleString = (lengthOfRules: number, previewCount: number) => {
  if (lengthOfRules) return `Preview ${previewCount} matching rows`;
  return "Create rules to narrow the rows below";
};
const Preview = ({ feedTableColumns }: Props) => {
  const [dataList] = useDataList<FeedTblRow>();
  const { windowInnerHeight } = useWindowSize();
  const { ruleConditions, previewCount } = useAssetBatchesContext();

  const columns: ITableColumn<FeedTblRow>[] =
    feedTableColumns
      ?.filter(col => typeof col.title === "string")
      .map(column => {
        return {
          key: column.key,
          title: column.title,
          dataIndex: [column.dataIndex],
          render: column.render,
          ...(column.key === "order_number"
            ? {
                width: "auto",
              }
            : {
                width: 200,
              }),
        } as ITableColumn<FeedTblRow>;
      }) ?? [];

  const { components, resizableColumns = [] } = useResizableTable<FeedTblRow>(
    columns
      .map(column => ({ ...column, name: column.title }))
      .filter(col => col.key !== COLUMN_ASSET_BATCHES),
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {getTitleString(
          ruleConditions.filter(rule => !!rule.value).length,
          previewCount,
        )}
      </div>
      <Spin spinning={dataList.isLoading}>
        <VirtualTable<FeedTblRow>
          scroll={{ y: windowInnerHeight - 231 }}
          rowKey={record => record.rowIdentifier}
          dataSource={dataList.rows}
          columns={resizableColumns}
          components={components}
          size={"small"}
        />
      </Spin>
    </div>
  );
};

export default Preview;
