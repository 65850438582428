import { isFeatureEnabled } from "utils/helpers";
import styles from "./Cell.module.scss";
import CellError from "./CellError";
import { useEffect, useState } from "react";

const MIN_CHARS_FOR_DISPLAY = 23;
type Props = {
  oldValue?: string | number;
  toggleClicked: () => void;
  editable?: boolean;
};

const Cell = ({ oldValue, toggleClicked, editable }: Props) => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  if (!mounted) {
    return null;
  }
  const displayEllipsis =
    typeof oldValue === "string" && oldValue.length > MIN_CHARS_FOR_DISPLAY;

  const valueToDisplay = displayEllipsis
    ? `${oldValue.substring(0, MIN_CHARS_FOR_DISPLAY)}...`
    : oldValue;

  const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);

  return (
    <div
      className={styles.cell}
      onClick={e => {
        if (editable === undefined || editable) {
          toggleClicked();
          e.stopPropagation();
        }
      }}
    >
      {oldValue ? (
        <div>{valueToDisplay}</div>
      ) : (
        !adEngineV2Enabled && <CellError errorMessage="No match!" />
      )}
    </div>
  );
};

export default Cell;
