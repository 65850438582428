import { ExportOutlined } from "@ant-design/icons";
import { Button, Col, notification, Progress, Row } from "antd";
import { ExportForOption } from "shared/types/assetExporter";
import styles from "./ProcessNotification.module.scss";

type Props = {
  message: string;
  status: string;
  percentage: number;
  getCsvDownloadUrl: () => void;
  notificationKey: string;
  exportFor: ExportForOption[];
  onClose: () => void;
  onAdLibClick: () => void;
};

const getProgressStatus = (status: string) => {
  if (status === "Error") return "exception";
  if (status === "Completed") return "success";
  return "active";
};

const Description = ({
  message,
  status,
  percentage,
  getCsvDownloadUrl,
  exportFor,
  onAdLibClick,
  notificationKey,
}: Props) => {
  const currStatus = getProgressStatus(status);
  const isDataProcessing = percentage < 90 && status !== "Error";
  const isCsvExport = exportFor.includes("CSV download");
  const isFtpExport = exportFor.includes("FTP");
  const isAdLibExport = exportFor.includes("Ad Library");
  const isCsvExportCompleted = percentage === 100;
  const isFtpExportCompleted = percentage === 100;
  const exportingText = "Exporting";
  const completedText = "Completed";
  const adLibText = "Review and Complete Import";
  const onCsvClick = () => getCsvDownloadUrl();

  return (
    <div>
      {isDataProcessing ? (
        <span>{message}</span>
      ) : (
        <Row>
          <Col span={12}>
            {status === "Error" ? (
              <div>{message}</div>
            ) : (
              <>
                {isAdLibExport && <div>Export for Ad Library</div>}
                {isCsvExport && <div>Export to CSV</div>}
                {isFtpExport && <div>Export to FTP</div>}
              </>
            )}
          </Col>
          <Col span={12}>
            {status === "Error" ? (
              <div className={styles.errorDescription}>
                <div className={styles.errorText}>Failed</div>
                <Button
                  type="link"
                  onClick={() => notification.close(notificationKey)}
                >
                  Return to Configure
                </Button>
              </div>
            ) : (
              <>
                {isAdLibExport && (
                  <div
                    className={`${styles.downloadBtn} ${
                      styles.statusDescription
                    }`}
                    onClick={onAdLibClick}
                  >
                    {adLibText}
                  </div>
                )}
                {isCsvExport &&
                  (isCsvExportCompleted ? (
                    <div
                      onClick={onCsvClick}
                      className={`${styles.downloadBtn} ${styles.statusDescription}`}
                    >
                      Download
                    </div>
                  ) : (
                    <div className={styles.statusDescription}>
                      {exportingText}
                    </div>
                  ))}
                {isFtpExport &&
                  (isFtpExportCompleted ? (
                    <div className={styles.statusDescription}>
                      {completedText}
                    </div>
                  ) : (
                    <div className={styles.statusDescription}>
                      {exportingText}
                    </div>
                  ))}
              </>
            )}
          </Col>
        </Row>
      )}

      <Progress percent={percentage} showInfo={true} status={currStatus} />
    </div>
  );
};

export const assetBatchExportNotification = (props: Props) => {
  notification.open({
    key: props.notificationKey,
    icon: (
      <div className={styles.notificationIcon}>
        <ExportOutlined />
      </div>
    ),
    message: <strong>Export Status</strong>,
    description: <Description {...props} />,
    duration: null,
    placement: "bottomRight",
    onClose: props.onClose,
    style: { width: "460px" },
  });
};
