import { LinkOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography, message } from "antd";
import { memo, useEffect } from "react";

import { useCampaignManagementIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { transformMillisecondsToSeconds } from "utils/helpers.date";
import styles from "./ShareAdsModal.module.scss";
import useAdReviewPreferences from "./shared/hooks/useAdReviewPreferences";
import useMutateSharedAdReview from "./shared/hooks/useMutateSharedAdReview";
import { useUser } from "shared/hooks/useUser";
import { extractPrimaryIdsFromCarouselAds } from "./shared/utils/helpers.ad";

interface IHandlers {
  onClose?: () => void;
}

type ShareAdsModalProps = IHandlers;

const ShareAdsModal = ({ onClose }: ShareAdsModalProps) => {
  const { selectedStore, selectedDateRange } = useAdReviewPreferences();

  const { selectedIds: selectedAdIds } = useCampaignManagementIds();

  const user = useUser();

  const {
    data,
    mutate: createSharedAdReview,
    isLoading,
    error,
    isError,
  } = useMutateSharedAdReview();

  const getUrl = () => {
    const baseUrl = `${window.location.origin}${window.location.pathname}/shared`;
    const queryParameters = `sharedAdReviewId=${data?.id}`;

    return `${baseUrl}?${queryParameters}`;
  };

  useEffect(() => {
    if (isError) {
      message.error(
        error?.message || `An error occurred while creating the link`,
      );
    }
  }, [error, isError]);

  useEffect(() => {
    message.config({ maxCount: 1 });
  }, []);

  useEffect(() => {
    if (!selectedStore) return;
    const since = transformMillisecondsToSeconds(selectedDateRange[0]);
    const to = transformMillisecondsToSeconds(selectedDateRange[1]);
    const adIds = extractPrimaryIdsFromCarouselAds(selectedAdIds);
    createSharedAdReview({
      account: {
        id: selectedStore.fbAccountId,
        name: selectedStore.name,
      },
      dateRange: {
        since: since,
        to: to,
      },
      platform: "facebook",
      sharedAdIds: adIds,
      createdBy: user?.email ?? "",
    });
  }, [
    createSharedAdReview,
    selectedAdIds,
    selectedDateRange,
    selectedStore,
    user?.email,
  ]);

  const handleClick = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(getUrl());
      message.info("URL copied to clipboard");
    }
  };

  return (
    <Modal
      centered
      visible
      closable
      onCancel={onClose}
      wrapClassName={styles.shareSessionModal}
      title="Share Link URL"
      width={572}
      footer={[
        <Button
          type="link"
          key="share-button"
          title="Copy URL"
          icon={<LinkOutlined style={{ marginTop: 4 }} />}
          onClick={handleClick}
        >
          Copy Link
        </Button>,
      ]}
    >
      <div>
        <Typography.Text>
          Share this URL for another viewer to see a preview of these ads. It
          has been copied to your clipboard.
        </Typography.Text>
        <Input
          value={isLoading ? "Loading..." : getUrl()}
          className={styles.input}
        />
      </div>
    </Modal>
  );
};

export default memo(ShareAdsModal);
