import EditCell from "./editableCell/EditCell";
import Cell from "./editableCell/Cell";
import { useMemo, memo } from "react";
import { useEditableCell } from "./hooks/useEditableCell";
import { useCellContext } from "./editableCell/CellContext";

interface EditableCellFields {
  value?: string | number;
  rowIdentifier?: string;
  index: string;
}
interface EditableCellHandlers {
  onSave: (
    value: string | number,
    oldValue: string | number,
    disableConfirm?: boolean,
  ) => void;
}

type EditableCellProps = EditableCellFields & EditableCellHandlers;

const EditableCell = ({
  value,
  index,
  rowIdentifier,
  onSave,
}: EditableCellProps) => {
  const { editingField, editingRow } = useCellContext();

  const { handleToggleClicked } = useEditableCell(
    index,
    rowIdentifier ?? "",
    onSave,
  );

  const CellMemo = useMemo(() => {
    return <Cell oldValue={value} toggleClicked={handleToggleClicked} />;
  }, [value, handleToggleClicked]);

  const EditCellMemo = useMemo(() => {
    return <EditCell value={value ?? ""} onSave={onSave} />;
  }, [value, onSave]);

  return editingField === index && editingRow === rowIdentifier
    ? EditCellMemo
    : CellMemo;
};

EditableCell.displayName = "EditableCell";

export default memo(EditableCell);
