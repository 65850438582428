import { useMemo } from "react";
import { IAd, ToolbarButton } from "shared/types/adLibrary";
import { useCampaignPlanner } from "./CampaignPlannerContext";
import { useParams } from "react-router-dom";
import { unionBy, xorBy } from "lodash";
import { DetailAdList } from "./shared/DetailAdList";
import { EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Messages } from "./shared/messages";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { IAdFields, fields } from "screens/adLibrary/fields";

export const CampaignPlannerDetail = () => {
  const { plannerId: id } = useParams<{ plannerId: string }>();

  const { updateCampaignPlannerAds, getPlanner } = useCampaignPlanner();

  const currentPlanner = useMemo(
    () => (id ? getPlanner(id) : undefined),
    [id, getPlanner],
  );

  const onDeleteClick = (adsToDelete: IAd[]) => {
    if (!currentPlanner) return;
    updateCampaignPlannerAds(
      currentPlanner,
      xorBy(currentPlanner?.adShells, adsToDelete, "id"),
      Messages.removePlannerAds(adsToDelete.length),
    );
  };

  const onAddAds = (ads: IAd[]) => {
    if (!currentPlanner) return;
    updateCampaignPlannerAds(
      currentPlanner,
      unionBy(currentPlanner?.adShells, ads, "id"),
      Messages.addPlannerAds(ads.length),
    );
  };

  const generateTableActions: (
    record?: IAd,
    onEditClick?: (record: IAd) => void,
  ) => ToolbarButton = (record, onEditClick) => ({
    Edit: {
      onClick: () => {
        record && onEditClick?.(record);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <EditOutlined />,
    },
    Delete: {
      onClick: (): void => onDeleteClick(record ? [record] : []),
      showInInlineMenu: true,
      showInContextMenu: true,
      extraInfo: {
        title: "Remove from campaign planner",
        icon: <MinusCircleOutlined style={{ fill: "red" }} />,
        tooltip: "Remove from campaign planner",
      },
      icon: <MinusCircleOutlined style={{ fill: "red" }} />,
    },
  });

  return (
    <DataListURLProvider<IAdFields, IAd> fields={fields}>
      <DetailAdList
        ads={currentPlanner ? currentPlanner.adShells : []}
        onDeleteClick={onDeleteClick}
        onAddAds={onAddAds}
        generateTableActions={generateTableActions}
      />
    </DataListURLProvider>
  );
};
