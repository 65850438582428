import { Checkbox, Select, Typography, Tooltip, Tag } from "antd";
import { isEqual } from "lodash";
import React, { FC, memo, useEffect, useState } from "react";
import { formatRate } from "utils/helpers.offer";

import { purchaseOptions } from "shared/constants/dataManagement";
import {
  IAssetBuild,
  IAssetBuildInstance,
  ISelectedOffer,
} from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";

import "./OfferSelection.scss";

interface IOfferSelectionProps {
  offers: IAssetBuild["offers"];
  instance?: IAssetBuildInstance;
}

interface IOfferSelectionHandlers {
  onPurchaseOptionChange: (vin: string, purchaseOptions: OfferType[]) => void;
  onOfferTypeChange: (
    vin: string,
    offerType: OfferType | "PurchasePlaceholder",
    checked: boolean,
  ) => void;
}

const OfferSelection: FC<
  IOfferSelectionProps & IOfferSelectionHandlers
> = props => {
  const [selectedPurchaseOptions, setSelectedPurchaseOptions] = useState<
    Record<string, OfferType[]>
  >({});
  const selectPurchaseOptions = purchaseOptions.map(option => ({
    title: option === OfferType.Purchase ? "Sales Price" : option,
    offerType: option,
  }));

  const { selectedOffer } = props.instance || {};
  useEffect(() => {
    if (!selectedOffer) {
      return;
    }
    setSelectedPurchaseOptions({
      ...selectedPurchaseOptions,
      [selectedOffer.vin]: selectedOffer.purchaseOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffer, props.instance]);

  // If selectedOffer.vin exists in the avaiable offers, we should render the offer.
  // However, if selectedOffer.vin does not exist in the available offers, it probably means that
  //  the user switched to different offers and removed previously selected offers.
  // If this happens, we need to allow user to switch to new offer.
  const foundSelectedOffer = props.offers.some(
    offer => offer.offerData.vin === selectedOffer?.vin,
  );

  const [overflowedVin, setOverflowedVin] = useState<string | null>(null);
  const tracker =
    selectedOffer?.offerSelectSortingTracker || selectedOffer?.offerTypes || [];

  // AV2-3061: PurchasePlaceholder caused issues with count badge
  const counterTracker = tracker.filter(
    offerType => offerType !== "PurchasePlaceholder",
  );
  const isPurchaseNotSelected = !tracker?.join(",").includes("Purchase");
  const selectedOfferVin = selectedOffer?.vin || selectedOffer?.offerData.vin;

  return (
    <div className="offer-selection">
      {props.offers.map(offer => {
        const {
          year = "",
          make = "",
          model = "",
          trim = "",
          vin = "",
        } = offer.offerData;

        const offerTitle = `${year} ${make} ${model} ${trim}`;
        const titleLengthLimit = 25;
        const isOverflowTitle = offerTitle.length > titleLengthLimit;

        // this determines if this vehicle should be selectable or not
        // Rule is if at least one of the offer type has been selection in an offer,
        // the other offers should not be selectable.
        const disableCheckbox =
          foundSelectedOffer && // if the selected offer not found, we should enable all checkboxes for user to select new offer
          selectedOffer &&
          offer.offerData.vin !== selectedOffer.vin;

        const displaySelect = offer.savedOfferTypes.find(
          type =>
            type === OfferType.Purchase ||
            type === OfferType["Purchase($ Off)"] ||
            type === OfferType["Purchase(%)"],
        );

        const isDifferentVinSelected =
          selectedOffer && offer.offerData.vin !== selectedOffer.vin;

        const disableSelect = isPurchaseNotSelected || isDifferentVinSelected;
        // NOTE: The purchase type selection box is at the botton of the view.
        //       Here, making sure that the purchase offer checkbox is the last item in the array if exists.
        const offerTypesExceptPurchase = offer.savedOfferTypes.filter(
          offerType => offerType.toLowerCase().indexOf("purchase") === -1,
        );
        const purchaseOfferTypes = offer.savedOfferTypes.filter(
          offerType => offerType.toLowerCase().indexOf("purchase") !== -1,
        );
        const sortedSavedOfferTypes = [
          ...offerTypesExceptPurchase,
          ...purchaseOfferTypes,
        ];

        return (
          <div
            key={`offer-selection-for-${vin}`}
            className={`selection-container offer-selection-choices ${vin}`}
          >
            <Tooltip
              className={`offer-title-tooltip-${vin}`}
              title={offerTitle}
              placement="right"
              visible={overflowedVin === vin}
            >
              <div
                className="title"
                onMouseEnter={e => {
                  e.preventDefault();

                  setOverflowedVin(vin);
                }}
                onMouseLeave={e => {
                  e.preventDefault();

                  setOverflowedVin(null);
                }}
              >
                <Typography.Text strong={true}>{`${offerTitle.substr(
                  0,
                  titleLengthLimit,
                )}${isOverflowTitle ? "..." : ""}`}</Typography.Text>
              </div>
            </Tooltip>

            <div className="content">
              <ul className="offer-type-selection">
                {sortedSavedOfferTypes.map(offerType => {
                  const checked =
                    !disableCheckbox && foundSelectedOffer
                      ? // handling other offer types other then the Purchase
                        selectedOffer?.offerTypes.includes(offerType) ||
                        // handling Purchase offer types
                        (offerType.startsWith("Purchase") &&
                          tracker?.join(",").includes("Purchase"))
                      : false;

                  const count = counterTracker.indexOf(offerType);

                  const showBadge =
                    offerType !== OfferType.Purchase &&
                    selectedOfferVin === vin &&
                    count !== -1;

                  return (
                    <li
                      key={`checkbox-for-${vin}-${offerType}`}
                      style={{ width: "100%" }}
                    >
                      <Checkbox
                        className={`offer-checkbox-${offerType}`}
                        disabled={disableCheckbox}
                        checked={checked}
                        onChange={e => {
                          const { target } = e;
                          const { checked: newChecked } = target;

                          props.onOfferTypeChange(
                            offer.offerData.vin,
                            offerType === OfferType.Purchase
                              ? "PurchasePlaceholder"
                              : offerType,
                            newChecked,
                          );
                        }}
                      >
                        {getOfferDescription(offerType, offer.offerData)}
                      </Checkbox>
                      <span>
                        {showBadge && <Tag color="blue">{count + 1}</Tag>}
                      </span>
                    </li>
                  );
                })}

                {displaySelect && (
                  <li className="purchase-option-select">
                    <Select
                      className="purchase-type-select"
                      key={`purchase-type-select`}
                      size="small"
                      mode="multiple"
                      value={selectedPurchaseOptions[offer.offerData.vin] || []}
                      disabled={disableSelect}
                      onChange={(value: OfferType[]) => {
                        props.onPurchaseOptionChange(vin, value);
                      }}
                    >
                      {selectPurchaseOptions.map(option => {
                        const count = counterTracker.indexOf(option.offerType);
                        const showBadge =
                          count !== -1 &&
                          selectedOfferVin === vin &&
                          selectedPurchaseOptions[
                            offer.offerData.vin
                          ]?.includes(option.offerType);

                        return (
                          <Select.Option
                            key={`purchase-type-select-option-${option.offerType}`}
                            value={option.offerType}
                            title={option.title}
                            style={{ minHeight: 24 }}
                          >
                            {option.title}
                            {showBadge && <Tag color="blue">{count + 1}</Tag>}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const returnMonetaryValueString = (numberString: string) => {
  const priceRegex = /^(\d{1,3}(\,)?)+(\.\d{2})?$/g;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (priceRegex.test(numberString)) {
    return `$${numberString}`;
  }

  return formatCurrency(numberString, currencyFormatter);
};

const getOfferDescription = (
  offerType: OfferType,
  offer: ISelectedOffer["offerData"],
) => {
  const {
    monthlyPayment,
    leaseMonthlyPayment,
    zeroDownLeaseMonthlyPayment,
    financePayment,
    aprRate,
    finalPrice,
  } = offer || {};

  switch (offerType) {
    case OfferType.Lease:
      return `Lease ${returnMonetaryValueString(
        monthlyPayment || leaseMonthlyPayment,
      )}/Mo`;

    case OfferType.ZeroDownLease:
      return `Zero Down ${returnMonetaryValueString(
        zeroDownLeaseMonthlyPayment,
      )}/Mo`;

    case OfferType.Finance:
      return `Finance ${returnMonetaryValueString(financePayment)}`;

    case OfferType.APR:
      return `APR ${aprRate ? formatRate(aprRate) + "%" : "-"}`;

    case OfferType.Purchase:
      return `Purchase ${returnMonetaryValueString(finalPrice)}`;
    default:
      return "-";
  }
};

const formatCurrency = (
  strNum: string,
  formatter: Intl.NumberFormat,
): string => {
  return strNum ? formatter.format(parseFloat(strNum)) : "-";
};

const areEqual = (
  prevProps: IOfferSelectionProps,
  nextProps: IOfferSelectionProps,
): boolean => {
  return isEqual(prevProps, nextProps);
};

export default memo(OfferSelection, areEqual);
