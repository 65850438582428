import { useCallback, useEffect, useRef, useState } from "react";
import { Drawer, Spin } from "antd";

import ShareSessionModal from "./adLoadDrawer/ShareSessionModal";
import AdLoadDrawerFooter from "./adLoadDrawer/AdLoadDrawerFooter";
import DrawerTitle from "./adLoadDrawer/DrawerTitle";
import SelectStep from "./adLoadDrawer/SelectStep";
import ReviewStep from "./adLoadDrawer/ReviewStep";
import LoadStep from "./adLoadDrawer/LoadStep";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useLocation } from "react-router-dom";
import { useUpdateSessionJson } from "../shared/hooks/useUpdateSessionJson";

import {
  DRAWER_WIDTH,
  stepUrlParamValues,
} from "./adLoadDrawer/shared/constants";

import { AdLoadStepKey, IAdLoadHistoryItem } from "shared/types/adLibrary";

import styles from "./AdLoadDrawer.module.scss";

const { DESTINATION_SELECTION, REVIEW_ADS_TO_LOAD, LOAD_ADS } = AdLoadStepKey;

interface IAdLoadDrawerProps {
  loading?: boolean;
  visible?: boolean;
}
interface IAdLoadDrawerHandlers {
  onClose: () => void;
}
type Props = IAdLoadDrawerProps & IAdLoadDrawerHandlers;

const AdLoadDrawer = ({ loading, visible, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    adLoadHistory,
    triggerUpdateProp,
    currentStep = null,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    adLoadReview: adLibrary.adLoad.review,
    currentStep: adLibrary.adLoad.currentStep,
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
    adLoadDestination: adLibrary.adLoad.destination,
    selectedAccounts: adLibrary.selectedFacebookAccounts,
    triggerUpdateProp: adLibrary.adLoad.triggerUpdateProp,
  }));

  const { title: sessionTitle, sessionId } = adLoadHistory || {};
  const [isShareSessionModalOpen, setIsShareSessionModalOpen] = useState(false);

  const updateSessionJson = useUpdateSessionJson();

  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const saveAdLoadData = useCallback(() => {
    /** Data should not save when viewing session history from /sessions page */
    if (!triggerUpdateProp || location.pathname === "/ad-library/sessions")
      return;
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(
      () => {
        updateSessionJson();
      },
      triggerUpdateProp === "title" ? 2000 : 1500,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdateProp, location.pathname]);

  useEffect(() => {
    saveAdLoadData();
  }, [saveAdLoadData]);

  const setCurrentStepBasedOnUrl = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const stepParam = urlParams.get("step");
    if (!stepParam) return;
    const step = Object.keys(stepUrlParamValues).find(
      key => stepUrlParamValues[key as AdLoadStepKey] === stepParam,
    );
    dispatch(
      setAdLoadState({
        prop: "currentStep",
        data: step ?? DESTINATION_SELECTION,
      }),
    );
  }, [dispatch, location.search]);

  useEffect(() => {
    setCurrentStepBasedOnUrl();
  }, [setCurrentStepBasedOnUrl]);

  const onChangeTitle = (title: string) => {
    dispatch(
      setAdLoadState({
        prop: "adLoadHistory",
        triggerUpdateProp: "title",
        data: {
          ...(adLoadHistory || {}),
          title: title,
        } as IAdLoadHistoryItem,
      }),
    );
  };

  return (
    <Drawer
      closable
      destroyOnClose
      visible={visible}
      width={DRAWER_WIDTH}
      maskClosable={false}
      className={`${styles.adLoadDrawer}${
        currentStep === LOAD_ADS ? ` ${styles.loadStep}` : ""
      }`}
      bodyStyle={{ paddingTop: 0 }}
      title={
        currentStep != DESTINATION_SELECTION && (
          <DrawerTitle
            sessionId={sessionId}
            currentStep={currentStep}
            isOnSessionsPage={location.pathname === "/ad-library/sessions"}
          />
        )
      }
      footer={
        <AdLoadDrawerFooter
          onClose={onClose}
          setIsShareSessionModalOpen={setIsShareSessionModalOpen}
        />
      }
      onClose={onClose}
    >
      <Spin spinning={loading} size="large" tip="Loading session data...">
        {currentStep === DESTINATION_SELECTION && <SelectStep />}
        {currentStep === REVIEW_ADS_TO_LOAD && <ReviewStep />}
        {currentStep === LOAD_ADS && <LoadStep />}
      </Spin>
      {isShareSessionModalOpen && sessionId && (
        <ShareSessionModal
          useWindowLocation
          sessionId={sessionId}
          sessionTitle={sessionTitle}
          onClose={() => {
            setIsShareSessionModalOpen(false);
          }}
          onChangeTitle={onChangeTitle}
        />
      )}
    </Drawer>
  );
};

export default AdLoadDrawer;
