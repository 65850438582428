/* eslint-disable react/display-name */
import { notification, Pagination } from "antd";
import { queryClient } from "queryClient";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import {
  createDataListConfig,
  DataListProvider,
  IDataListColumns,
} from "shared/components/dataList";
import {
  COLUMN_ASSET_BATCHES,
  PROCESS_NOTIFICATION_KEY,
} from "shared/constants/assetExporter";
import {
  fetchFeedTblRow,
  IFilter,
} from "shared/hooks/assetExporter/useFeedConfigFetch";
import { useFeedConfigUpdate } from "shared/hooks/assetExporter/useFeedConfigUpdate";
import {
  FeedRow,
  FeedTblCol,
  FeedTblRow,
  TSortArgs,
} from "shared/types/assetExporter";
import { checkFilterMatch } from "utils/helpers";
import "./FeedConfiguration.scss";
import { AdLibExportDrawer } from "./feedConfiguration/AdLibExportDrawer";
import { FeedConfigurationCell } from "./feedConfiguration/FeedConfigurationCell";
import { FeedList } from "./feedConfigurationV2/FeedList";
import { FeedProvider } from "./feedConfigurationV2/shared/contexts/FeedContext";
import { getColumnTitle } from "./feedConfigurationV2/shared/utils";
import { StringParam, useQueryParam } from "use-query-params";

const PAGE_SIZE = 50;

const getDisplayingStr = (
  currentPage: number,
  totalRows: number,
  filteredTotalCount: number,
) => {
  const rowStartIndex = !totalRows ? 0 : PAGE_SIZE * (currentPage - 1) + 1;
  const rowEndIndex = !totalRows ? 0 : rowStartIndex + totalRows - 1;
  return `displaying ${rowStartIndex} to ${rowEndIndex} of ${filteredTotalCount} items`;
};

const FeedConfigurationV2 = () => {
  const actions = useAdEngineActions();
  const [feedId, setFeedId] = useState("");
  const [feedTableColumns, setFeedTableColumns] = useState<FeedTblCol[]>();
  const [searchValue, setSearchValue] = useState("");
  const [reqSearchValue, setReqSearchValue] = useState("");
  const [filterArgs, setFilterArgs] = useState<IFilter[]>();
  const [sortArgs, setSortArgs] = useState<TSortArgs>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalFilteredRows, setTotalFilteredRows] = useState<FeedTblRow[]>([]);
  const [filteredTotalCount, setFilteredTotalCount] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const debouncedRef = useRef<NodeJS.Timeout>();

  const updateFilterArgs = (newFilterArgs: IFilter[] | undefined) => {
    setFilterArgs(newFilterArgs);
    setCurrentPage(1);
  };

  const assetBatchesQK = [
    COLUMN_ASSET_BATCHES,
    feedId,
    reqSearchValue,
    filterArgs,
    sortArgs,
    currentPage,
  ];

  const { mutate: patchFeedRowField, isLoading } =
    useFeedConfigUpdate(assetBatchesQK);

  const handlePatchValue = useCallback(
    (
      rowIdentifier: string,
      field: string,
      value: string | number | boolean,
      oldValue: string | number | boolean,
    ) => {
      if (!rowIdentifier) return;

      patchFeedRowField({
        feedId,
        rowIdentifier,
        field,
        value,
        oldValue,
      });

      queryClient.invalidateQueries("feedCrons");
    },

    [feedId, patchFeedRowField],
  );

  const getTableColumns = useCallback(
    (orderedColumns: string[]) => {
      return ["order_number"]
        .concat(
          orderedColumns.filter(
            columnsName =>
              !["rowIdentifier", "lastUpdated", "assetBatchId"].includes(
                columnsName,
              ),
          ),
        )
        .map(key => {
          const title: string | ReactNode = getColumnTitle(key);
          const dataIndex = key;
          const leftFixedColumns = ["order_number", COLUMN_ASSET_BATCHES];
          const fixed = leftFixedColumns.includes(key) ? "left" : undefined;
          const isAssetBatchColumn = key === COLUMN_ASSET_BATCHES;

          return {
            title,
            dataIndex,
            editable: true,
            key,
            fixed,
            sorter: true,
            render: (value: string, row: FeedRow) => {
              return (
                <FeedConfigurationCell
                  isSmartCol={isAssetBatchColumn}
                  value={value}
                  row={row}
                  field={key}
                  onEditCell={handlePatchValue}
                />
              );
            },
          };
        });
    },
    [handlePatchValue],
  );

  const onComponentUnmount = useCallback(() => {
    notification.close(PROCESS_NOTIFICATION_KEY);
    actions.setIsExportByUser(false);
  }, [actions]);

  const [mountFeedId] = useQueryParam("feedId", StringParam);

  const updateFeedDataOnMount = useCallback(() => {
    mountFeedId && setFeedId(mountFeedId);
  }, [mountFeedId]);

  useEffect(updateFeedDataOnMount, [updateFeedDataOnMount]);
  useEffect(() => onComponentUnmount, [onComponentUnmount]);

  useEffect(() => {
    if (!feedId) return;
    const getColumns = async () => {
      const { orders: newSortedColumns } = await fetchFeedTblRow({
        feedId,
        page: "1",
      });

      const tableColumns = getTableColumns(newSortedColumns);
      setFeedTableColumns(tableColumns);
    };

    getColumns();
  }, [feedId, getTableColumns]);

  const columns: IDataListColumns<FeedTblCol> =
    feedTableColumns &&
    Object.assign(
      {},
      ...feedTableColumns.map(feedTableColumn => ({
        [feedTableColumn.key]: {
          display: (value: string) => feedTableColumn.key + ": " + value,
          filterFn: (value: any, record: any) => {
            if (Array.isArray(value))
              return value.some(v => record?.[feedTableColumn.key].includes(v));
            return checkFilterMatch(
              value?.toString(),
              record?.[feedTableColumn.key],
            );
          },
        },
      })),
    );

  const dataListConfig = createDataListConfig(columns, {
    queryKey: assetBatchesQK,
    enabled: !!feedId && !isLoading,
    queryFn: async () => {
      const {
        rows,
        totalFeedCount: newTotalFeedCount,
        rowsCount: newRowsCount,
        totalFilteredRows: newTotalFilteredRows,
        filteredCount: newFilteredCount,
      } = await fetchFeedTblRow({
        feedId,
        page: currentPage.toString(),
        searchArgs: reqSearchValue,
        sortArgs,
        filterArgs,
      });
      setTotalCount(newTotalFeedCount);
      setTotalFilteredRows(newTotalFilteredRows);
      setFilteredTotalCount(newFilteredCount);
      setTotalRows(newRowsCount);
      return rows;
    },
    cacheTime: 0,
  });

  const setSearchValueHandler = (val: string) => {
    setSearchValue(val);

    if (debouncedRef.current) clearTimeout(debouncedRef.current);

    debouncedRef.current = setTimeout(() => {
      setReqSearchValue(val);
      setCurrentPage(1);
    }, 700);
  };

  return (
    <>
      <AdLibExportDrawer feedId={feedId} />
      <div className="feed-output-container">
        {feedTableColumns && (
          <DataListProvider config={dataListConfig}>
            <FeedProvider columns={feedTableColumns} feedId={feedId}>
              <FeedList
                feedId={feedId}
                feedTableColumns={feedTableColumns}
                searchValue={searchValue}
                setSearchValue={setSearchValueHandler}
                setSortArgs={setSortArgs}
                setFilterArgs={updateFilterArgs}
                filterArgs={filterArgs}
                totalCount={totalCount}
                totalFilteredRows={totalFilteredRows}
                filteredTotalCount={filteredTotalCount}
              />

              <div className="pagination-container">
                <span className="displaying-desc-container">
                  {getDisplayingStr(currentPage, totalRows, filteredTotalCount)}
                </span>
                <Pagination
                  current={currentPage}
                  pageSize={PAGE_SIZE}
                  showSizeChanger={false}
                  total={filteredTotalCount}
                  onChange={(pageNumber: number) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </FeedProvider>
          </DataListProvider>
        )}
      </div>
    </>
  );
};

export default FeedConfigurationV2;
