import { usePdfGeneratorNotification as useAdReviewPdfGeneratorNotification } from "screens/campaignManagement/adReview/shared/hooks/usePdfGeneratorNotification";
import { useGenerateExportNotification as useAdLibraryExportNotification } from "screens/adLibrary/library/adList/shared/hooks/useGenerateExportNotification";
import { useAssetBatchExportNotification } from "shared/hooks/assetExporter/useAssetBatchExportNotification";

export const NotificationsDaemon = () => {
  useAdReviewPdfGeneratorNotification();
  useAdLibraryExportNotification();
  useAssetBatchExportNotification();

  return null;
};
