import { useCallback } from "react";
import { useToggle } from "shared/hooks/useToggle";
import { useCellContext } from "../editableCell/CellContext";

export const useEditableCell = (
  index?: string,
  rowIdentifier?: string,
  onSave?: (
    value: string | number,
    oldValue: string | number,
    disableConfirm?: boolean,
  ) => void,
) => {
  const toggleClicked = useToggle(false);
  const {
    newValue,
    setNewValue,
    setEditingField,
    isEditing,
    setIsEditing,
    oldValue,
    setEditingRow,
    setOldValue,
  } = useCellContext();

  const handleSave = useCallback(
    (
      value: string | number,
      oldValue: string | number,
      disableConfirm?: boolean,
    ) => {
      onSave?.(value, oldValue, disableConfirm);
      setIsEditing(false);
      setNewValue("");
      setOldValue("");
      setEditingField("");
      setEditingRow("");
    },
    [
      onSave,
      setIsEditing,
      setEditingField,
      setEditingRow,
      setNewValue,
      setOldValue,
    ],
  );

  const checkIfDirty = useCallback(() => {
    const isDirty =
      newValue !== null && oldValue !== null && newValue !== oldValue;
    if (isDirty) {
      handleSave(newValue, oldValue, true);
      return;
    }

    setIsEditing(false);
    setNewValue("");
    setOldValue("");
    setEditingField("");
    setEditingRow("");
    toggleClicked[1]();
  }, [
    newValue,
    oldValue,
    handleSave,
    toggleClicked,
    setIsEditing,
    setNewValue,
    setOldValue,
    setEditingField,
    setEditingRow,
  ]);

  const handleToggleClicked = useCallback(() => {
    if (isEditing) {
      checkIfDirty();
      return;
    }

    setIsEditing(true);
    setNewValue(oldValue ?? "");
    setEditingField(index ?? "");
    setEditingRow(rowIdentifier ?? "");
    toggleClicked[1]();
  }, [
    setIsEditing,
    setNewValue,
    setEditingField,
    setEditingRow,
    checkIfDirty,
    isEditing,
    toggleClicked,
    oldValue,
    index,
    rowIdentifier,
  ]);

  const handleTextAreaChange = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement>,
      setTextAreaValue: React.Dispatch<React.SetStateAction<string | number>>,
    ) => {
      setTextAreaValue(e.currentTarget.value);
      setNewValue(e.currentTarget.value);
    },
    [setNewValue],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
        const start = e.currentTarget.selectionStart;
        const end = e.currentTarget.selectionEnd;
        const value = e.currentTarget.value;
        e.currentTarget.value =
          value.substring(0, start) + "\n" + value.substring(end);
        e.currentTarget.selectionStart = e.currentTarget.selectionEnd =
          start + 1;
      } else if (e.key === "Enter") {
        e.preventDefault();
        checkIfDirty();
      }
    },
    [checkIfDirty],
  );

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setIsEditing(false);
        setNewValue("");
        setOldValue("");
        setEditingField("");
        setEditingRow("");
        toggleClicked[1]();
      }
    },
    [
      setIsEditing,
      setNewValue,
      setOldValue,
      toggleClicked,
      setEditingField,
      setEditingRow,
    ],
  );

  return {
    handleToggleClicked,
    handleSave,
    checkIfDirty,
    handleTextAreaChange,
    handleKeyDown,
    handleEscape,
  };
};
