import { VideoCameraOutlined } from "@ant-design/icons";
import { Select, Space, Tooltip } from "antd";
import { useFetchProductSets } from "shared/hooks/adLibrary/facebook/useFetchProductSets";

type Props = {
  productSetId: string | undefined;
  onChange: (productSetId: string) => void;
  onSelectConvertToVideo: () => void;
  convertToVideo: boolean;
  productCatalogId: string | undefined;
  style?: {
    width: number;
  };
};

const convertToVideoOption = {
  value: "CONVERT_TO_VIDEO",
  label: (
    <Space>
      <VideoCameraOutlined />
      <span>Convert Ad to Video</span>
    </Space>
  ),
} as const;

const MINIMUM_PRODUCT_COUNT = 4;

const ProductSetSelect = ({
  productSetId,
  onChange,
  productCatalogId,
  onSelectConvertToVideo,
  convertToVideo,
  style,
}: Props) => {
  const { isLoading: isLoadingProductSets, data: productSets = [] } =
    useFetchProductSets({
      productCatalogId: productCatalogId ?? "",
    });

  const convertToVideoValue = convertToVideo
    ? convertToVideoOption.value
    : undefined;
  const productSetValue = !isLoadingProductSets ? productSetId : undefined;
  const selectValue = convertToVideoValue ?? productSetValue;

  const productSetOptions = productSets.map(productSet => {
    const insufficientProducts =
      (productSet.product_count ?? Infinity) < MINIMUM_PRODUCT_COUNT;

    const label = (
      <Tooltip
        title={
          insufficientProducts
            ? `At least ${MINIMUM_PRODUCT_COUNT} products are required`
            : undefined
        }
      >
        {productSet.name} ({productSet.product_count})
      </Tooltip>
    );

    return {
      value: productSet.id,
      label,
      disabled: insufficientProducts,
    };
  });

  return (
    <Select
      showSearch
      style={style}
      value={selectValue}
      onChange={value => {
        if (value === convertToVideoOption.value) {
          onSelectConvertToVideo();
          return;
        }
        onChange(value);
      }}
      options={[convertToVideoOption, ...productSetOptions]}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label?.toString() ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      loading={isLoadingProductSets}
      disabled={isLoadingProductSets || !productCatalogId}
      placeholder={
        isLoadingProductSets
          ? "Loading product sets..."
          : "Select a product set"
      }
      notFoundContent={`Facebook product sets not found`}
    />
  );
};

export default ProductSetSelect;
