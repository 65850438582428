import API from "services";
import { useQuery } from "react-query";
import {
  IGetDashboardsResponse,
  IGetDashboardResponse,
  IGetPublicDashboardResponse,
} from "shared/types/dashboardManagement";
import { queryKeys } from "./queryKeys";

const getDashboard = (id: string | undefined) => async () => {
  if (!id) return;
  const { result, error } = await API.services.dashboardManagement.getDashboard(
    id,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const getPublicDashboard = (id: string) => async () => {
  const { result, error } =
    await API.services.dashboardManagement.getPublicDashboard(id);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const getDashboards = async () => {
  const { result, error } =
    await API.services.dashboardManagement.getDashboards();

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const getDashboardAvailableModelsNames = () => async () => {
  const { result, error } =
    await API.services.dashboardManagement.getDashboardAvailableModels();
  if (error) {
    throw Error(error.message);
  }

  return result?.map(({ name }) => name) || [];
};

export const useFetchDashboard = (id: string | undefined) => {
  return useQuery<IGetDashboardResponse["result"] | undefined, Error>(
    [queryKeys.dashboards, id],
    getDashboard(id),
    {
      enabled: !!id,
    },
  );
};

export const useFetchPublicDashboard = (
  id: string,
  onSuccess: (data: IGetPublicDashboardResponse["result"]) => void | undefined,
) => {
  return useQuery<IGetPublicDashboardResponse["result"], Error>(
    ["publicDashboards", id],
    getPublicDashboard(id),
    {
      onSuccess,
    },
  );
};

export const useFetchDashboards = () => {
  const result = useQuery<IGetDashboardsResponse["result"], Error>(
    "dashboards",
    getDashboards,
    {
      staleTime: 300000,
    },
  );

  const dashboards = result.data?.dashboards;

  return {
    ...result,
    dashboards,
  };
};

export const useFetchDashboardAvailableModelsNames = () => {
  return useQuery<string[], Error>(
    [queryKeys.dashboardAvailableModels],
    getDashboardAvailableModelsNames(),
  );
};
