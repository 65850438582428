import { useEffect } from "react";
import { Alert } from "antd";

import classNames from "classnames";
import actions from "../../redux/rootActions";

import styles from "./Layout.module.scss";
import { getPublicPaths } from "utils/helpers";
import { configMenus } from "configMenus";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useLocation } from "react-router-dom";
import useFetchClientVersion from "shared/hooks/useFetchClientVersion";

const NewVersionAlert = () => {
  // TODO: will be used potentially in the future
  const { pathname: currentPath, search } = useLocation();
  const dispatch = useAppDispatch();

  const { updateAvailable } = useFetchClientVersion();

  useEffect(() => {
    const publicPaths = getPublicPaths(configMenus);
    // FIXME: we don't need to check for user existence here
    // dispatch(actions.auth.signInCheck(currentPath, search, publicPaths));

    dispatch(actions.configuration.setConfiguration());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Alert
      className={classNames({
        [styles.notification]: true,
        [styles.hiddenNewVersionAlert]: !updateAvailable,
      })}
      message={
        <div
          onClick={e => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          A new version is available! Click here to refresh.
        </div>
      }
      banner={true}
      closable={true}
    />
  );
};

export default NewVersionAlert;
