import {
  ICreateTagResponse,
  IResponse,
  ITemplate,
  ITemplateTag,
} from "shared/types/designStudio";

import {
  createTagBegin,
  createTagFail,
  createTagSuccess,
  deleteTemplateBegin,
  deleteTemplateFail,
  deleteTemplateSuccess,
  duplicateTemplateBegin,
  duplicateTemplateFail,
  duplicateTemplateSuccess,
  updateTemplateBegin,
  updateTemplateFail,
  updateTemplateSuccess,
} from "redux/designStudio/designStudio.slice";
import { AppThunk } from "redux/store";
import API from "services";
import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";

export const createTag =
  (tag: ITemplateTag): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createTagBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const createTagUrl = (config as IConfig).services.designStudio.createTagUrl;
    const request: RequestInfo = new Request(createTagUrl, {
      method: "POST",
      body: JSON.stringify({ tag }),
    });

    const { result, error } = await API.send<ICreateTagResponse>(request);

    if (error || !result) {
      dispatch(createTagFail(tag));

      return;
    }

    const { tag: newTag } = result;

    dispatch(createTagSuccess(newTag));
  };

export const duplicateTemplate =
  (template: ITemplate): AppThunk =>
  async (dispatch, getState) => {
    dispatch(duplicateTemplateBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      (config as IConfig).services.designStudio.createTemplateUrl,
      {
        method: "POST",
        body: JSON.stringify({
          template,
          duplicate: true,
        }),
      },
    );

    const { result, error } = await API.send<IResponse<ITemplate>>(request);

    if (error) {
      return dispatch(duplicateTemplateFail(error));
    }

    if (!result || !result.template) {
      return dispatch(
        duplicateTemplateFail(
          new GenericError({
            message: "Template was no able to duplicated.",
          }),
        ),
      );
    }

    const { template: createdTemplate } = result;

    dispatch(duplicateTemplateSuccess(createdTemplate));
  };

export const updateTemplate =
  (
    template: ITemplate,
    status?: string,
    htmlText?: string,
    zipFileId?: string,
  ): AppThunk =>
  async dispatch => {
    dispatch(updateTemplateBegin());

    const { result, error } = await API.services.designStudio.updateTemplate(
      template,
      htmlText,
      zipFileId,
    );

    if (error || !result?.template) {
      return dispatch(
        updateTemplateFail(
          new GenericError({ message: error?.message || "Unknown error!" }),
        ),
      );
    }

    return dispatch(
      updateTemplateSuccess({ template: result.template, status }),
    );
  };

export const deleteTemplate =
  (id: string, createdAt: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(deleteTemplateBegin(id));

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      `${config!.services.designStudio.deleteTemplateUrl}/${id}/${createdAt}`,
      {
        method: "delete",
      },
    );

    const { result, error } = await API.send<any>(request);

    if (error) {
      return dispatch(deleteTemplateFail(error));
    }

    const { template } = result;

    return dispatch(deleteTemplateSuccess(template as ITemplate));
  };
