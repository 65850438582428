import { useMemo, useState } from "react";
import CardListContainer from "shared/components/CardListContainer";
import { useFetchDashboards } from "shared/hooks/dashboardManagement/useFetchDashboards";

import MetricsCard from "./MetricsCard";
import MetricsHeader from "./MetricsHeader";

import { useFetchDashboardSettings } from "shared/hooks/dashboardManagement/useFetchDashboardSettings";
import { useUser } from "shared/hooks/useUser";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { IDashboard } from "shared/types/dashboardManagement";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

export const DashboardList = ({
  setShareDashboard,
  setShowLinkModal,
}: {
  setShareDashboard: (dashboard?: IDashboard) => void;
  setShowLinkModal: (showLinkModal: boolean) => void;
}) => {
  const user = useUser();
  const isAdmin = useIsAdmin();
  const email = user?.email ?? "";
  const { data: dashboardSettings } = useFetchDashboardSettings(isAdmin, email);
  const { dashboards } = useFetchDashboards();
  const [searchValue, setSearchValue] = useState<string>("");

  const displayedDashboardList = useMemo(() => {
    if (!isAdmin && dashboardSettings) {
      const searchValueFilter = dashboards?.filter(dashboard => {
        const dashboardIdsFromUser = user?.dashboards;
        if (!dashboardIdsFromUser || !dashboard?.id) return false;
        return (
          [dashboard?.title, dashboard?.account, dashboard?.lookerId].some(
            value => value?.toLowerCase().includes(searchValue.toLowerCase()),
          ) && dashboardIdsFromUser.includes(dashboard?.id)
        );
      });

      const defaultExternalFilter = dashboards?.filter(dashboard => {
        const dashboardIdsFromUser = user?.dashboards;
        if (!dashboardIdsFromUser || !dashboard?.id) return false;
        return dashboardIdsFromUser.includes(dashboard.id);
      });

      return searchValue ? searchValueFilter : defaultExternalFilter;
    }

    return searchValue
      ? dashboards?.filter(dashboard =>
          [dashboard?.title, dashboard?.account, dashboard?.lookerId].some(
            value => value?.toLowerCase().includes(searchValue.toLowerCase()),
          ),
        )
      : dashboards;
  }, [dashboards, isAdmin, searchValue, user?.dashboards, dashboardSettings]);

  const { windowInnerHeight } = useWindowSize();
  const allAvailableHeight = windowInnerHeight - 160;

  return (
    <>
      <MetricsHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {!!displayedDashboardList?.length && (
        <CardListContainer
          virtualized
          height={allAvailableHeight}
          gridArgs={{ cardWidth: 380, cardHeight: 400 }}
          center={false}
          cards={displayedDashboardList.map(dashboard => {
            return (
              <MetricsCard
                key={dashboard.id}
                dashboard={dashboard}
                dashboardSetting={dashboardSettings?.dashboardSettings.find(
                  setting => setting.dashboardId === dashboard.id,
                )}
                setShowLinkModal={setShowLinkModal}
                setShareDashboard={setShareDashboard}
              />
            );
          })}
        />
      )}
    </>
  );
};
