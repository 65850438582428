import { getAccessToken } from "./auth";
import { config } from "./config";

export const getPresignedUrl = async (
  folderId: string,
  file: File,
  assetId?: string,
): Promise<MGPresignedUrl> => {
  const params = new URLSearchParams({
    folderid: folderId,
    filesize: String(file.size),
    filename: file.name,
    contenttype: file.type,
    ...(!!assetId && { assetid: assetId, intent: "newversion" }),
  });

  const res = await fetch(
    `${config.wdBaseUrl}/awss3/generateupload?${params}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  if (res.ok) {
    return res.json();
  }

  throw new Error("Failed to fetch presigned url.");
};

export const finishUpload = async (
  processid: string,
): Promise<{ id: string }> => {
  const res = await fetch(
    `${config.wdBaseUrl}/awss3/finishupload/${processid}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  if (res.ok) {
    return res.json();
  }

  throw new Error("Failed to finish upload.");
};

export const uploadToS3 = (url: string, body: File) => {
  return fetch(url, {
    method: "PUT",
    body,
  });
};
