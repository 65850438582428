import { useEffect, useState } from "react";
import {
  createClient,
  Entry,
  ContentfulCollection,
  RichTextContent,
} from "contentful";
import HelpMenu from "./helpMenu/HelpMenu";
import HelpContent from "./helpContent/HelpContent";
import { Layout, Input, Typography } from "antd";
import { ReactComponent as ConstellationLockup } from "statics/images/constellation-lockup.svg";
import styles from "./HelpPages.module.scss";
import { months } from "utils/helpers.date";
import { useNavigate } from "react-router-dom";
import { IDataTableError } from "shared/types/errors";
import {
  contentfulDeliveryToken,
  contentfulEnvironment,
  contentfulSpaceId,
} from "utils/constants";

const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Link } = Typography;

type ContentItem = {
  title: string;
  content: RichTextContent;
};

const HelpPages = () => {
  const navigate = useNavigate();

  const [faqFields, setFAQFields] = useState<ContentItem[]>([]);
  const [cmpFields, setCMPFields] = useState<ContentItem[]>([]);
  const [faqTitles, setFAQTitles] = useState<string[]>([]);
  const [cmpTitles, setCMPTitles] = useState<string[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<{
    month: string;
    year: string;
  }>({
    month: months[0],
    year: "2022",
  });

  const fetchContent = async () => {
    try {
      if (!contentfulSpaceId || !contentfulDeliveryToken) return;

      const client = createClient({
        space: contentfulSpaceId,
        accessToken: contentfulDeliveryToken,
        environment: contentfulEnvironment,
      });

      const { items }: ContentfulCollection<Entry<ContentItem>> =
        await client.getEntries();

      if (!items.length) return;

      const lastUpdated = {
        month: months[parseInt(items[0].sys.updatedAt.slice(5, 6))],
        year: items[0].sys.updatedAt.slice(0, 4),
      };

      setLastUpdated(lastUpdated);

      const faqItems = items.filter(
        item => item.sys.contentType.sys.id === "faq",
      );
      const cmpItems = items.filter(
        item => item.sys.contentType.sys.id === "cmp-workflow",
      );
      const faqContent = faqItems
        .map((item: Entry<ContentItem>) => {
          return {
            title: item.fields.title,
            content: item.fields.content,
          };
        })
        .reverse();
      const cmpContent = cmpItems
        .map((item: Entry<ContentItem>) => {
          return {
            title: item.fields.title,
            content: item.fields.content,
          };
        })
        .reverse();
      const faqTitles = faqItems
        .map((item: Entry<ContentItem>) => item.fields.title)
        .reverse();

      //Need to sort by 5th index of CMP title because these are steps:
      //The 5th index is the step number.
      const cmpTitles = cmpItems
        .map((item: Entry<ContentItem>) => item.fields.title)
        .sort((firstTitle, secondTitle) =>
          firstTitle[5] < secondTitle[5] ? -1 : 1,
        );

      setFAQTitles(faqTitles);
      setCMPTitles(cmpTitles);
      setFAQFields(faqContent);
      setCMPFields(cmpContent);
    } catch (error) {
      const err = error as IDataTableError;
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  useEffect(() => {
    if (!searchValue) return;
    const allTitles = faqTitles.concat(cmpTitles);
    const newSelectedTitle = allTitles.find(title =>
      title.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const faqPaths = [
      "ad-library",
      "user-management",
      "store-manager",
      "batch-ads-creation",
      "instant-experience",
      "ad-load",
    ];
    const cmpPaths = ["step-0", "step-1", "step-2", "step-3"];
    const matchedSearchValue = faqPaths.concat(cmpPaths).find(path => {
      const pathToTitleToMatch = path
        .replace(/-/g, " ")
        .toLowerCase()
        .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());

      return newSelectedTitle?.includes(pathToTitleToMatch);
    });

    setSelectedTitle(newSelectedTitle || "");
    navigate(`/help/${matchedSearchValue}`);
  }, [cmpTitles, faqTitles, navigate, searchValue]);

  return (
    <Layout
      style={{
        width: "100%",
        height: "100vh",
        padding: "2rem",
        backgroundColor: "white",
      }}
    >
      <Sider className={styles.SiderMenu} theme="light" width={306}>
        <Header style={{ backgroundColor: "white", padding: 0 }}>
          <ConstellationLockup />
        </Header>
        <HelpMenu
          faqTitles={faqTitles}
          cmpTitles={cmpTitles}
          selectedTitle={selectedTitle}
          lastUpdated={lastUpdated}
          setSelectedTitle={setSelectedTitle}
        />
        <div className={styles.footer}>
          <span>© 2023 Constellation Software, Inc</span>
          <Link
            href="https://helloconstellation.com/legal/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
      </Sider>
      <Content
        style={{
          backgroundColor: "white",
          height: "100%",
          overflowX: "scroll",
          paddingLeft: "1rem",
          marginRight: "20%",
        }}
      >
        <h1>How can we help?</h1>
        <Search
          value={searchValue}
          placeholder="Search"
          onChange={value => setSearchValue(value.target.value)}
        />
        <p className={styles.selectedTitle}>{selectedTitle}</p>
        <HelpContent faqFields={faqFields} cmpFields={cmpFields} />
      </Content>
    </Layout>
  );
};

export default HelpPages;
