import {
  InfiniteData,
  MutationOptions,
  useMutation,
  useQueryClient,
} from "react-query";
import API from "services";
import { IGetTemplatesResult, ITemplate } from "shared/types/designStudio";
import { OperationMode } from "shared/types/inputValues";

type TVariables = {
  template: ITemplate;
  htmlText?: string;
  zipFileId?: string;
  mode: OperationMode;
};

export type OnCompleteFunc = (
  variables: TVariables,
  updatedTemplate: ITemplate,
) => void;

const updateTemplate = async ({
  mode,
  template,
  htmlText,
  zipFileId,
}: {
  mode: OperationMode;
  template: ITemplate;
  htmlText?: string;
  zipFileId?: string;
}) => {
  const { result, error } =
    mode === "CREATE" || mode === "DUPLICATE"
      ? await API.services.designStudio.createTemplate(
          template,
          htmlText,
          zipFileId,
          mode === "DUPLICATE",
        )
      : await API.services.designStudio.updateTemplate(
          template,
          htmlText,
          zipFileId,
        );
  if (error) {
    throw Error(`${error}`);
  }

  return result?.template || null;
};

const updateTemplateQueryData = (
  variables: TVariables,
  data: InfiniteData<IGetTemplatesResult>,
  updatedTemplate: ITemplate,
) => ({
  ...data,
  pages: data.pages.reduce<Array<IGetTemplatesResult>>((acc, page, idx) => {
    switch (variables.mode) {
      case "DUPLICATE":
      case "CREATE":
        // insert in the last page
        if (idx === data.pages.length - 1) {
          return [
            ...acc,
            {
              ...page,
              templates: [...page.templates, updatedTemplate],
            },
          ];
        }

        return [...acc, page];

      case "UPDATE":
        const foundTemplate = page.templates.some(
          t => t.id === updatedTemplate.id,
        );
        if (!foundTemplate) return [...acc, page]; // if not found in current page, we can safely skip to next page.

        return [
          ...acc,
          {
            ...page,
            templates: page.templates.map(t =>
              t.id === updatedTemplate.id ? updatedTemplate : t,
            ),
          },
        ];

      default:
        return acc;
    }
  }, []),
});

export const useMutateTemplate = (onComplete?: OnCompleteFunc) => {
  const queryClient = useQueryClient();

  const onSuccess: (
    onComplete?: OnCompleteFunc,
  ) => MutationOptions<ITemplate | null, Error, TVariables>["onSuccess"] =
    onComplete => (updatedTemplate, variables) => {
      if (!updatedTemplate) return;

      const data = queryClient.getQueryData<
        InfiniteData<IGetTemplatesResult> | undefined
      >("templates");

      if (!data) return; // if cached templates dont exist, we can safely return because the updated data will be fetched when needed.

      const updatedData: InfiniteData<IGetTemplatesResult> =
        updateTemplateQueryData(variables, data, updatedTemplate);

      queryClient.setQueryData("templates", updatedData);

      onComplete?.(variables, updatedTemplate);
    };

  return useMutation<ITemplate | null, Error, TVariables>(
    "mutateTemplate",
    updateTemplate,
    {
      onSuccess: onSuccess(onComplete),
    },
  );
};
