import { Drawer } from "antd";
import SelectStep from "./adLoadDrawer/SelectStep";
import { useAds } from "../shared/hooks/useAds";
import LoadStep from "./adLoadDrawer/LoadStep";
import { useCurrentSession, useSessionParam } from "./hooks/useCurrentSession";
import { AdLoadSteps } from "./adLoadDrawer/AdLoadSteps";
import { AdLoadFooter } from "./adLoadDrawer/AdLoadFooter";
import Spinner from "./shared/Spinner";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import AssignDestsStep from "./adLoadDrawer/AssignDestsStep";
import { useSelectedAcctCampaigns } from "./hooks/useSelectedAcctCampaigns";
import AssignProductSetsStep from "./adLoadDrawer/AssignProductSetsStep";
import { AdLoadStep } from "../facebookUtils/adProto.types";
import { ReactNode } from "react";
import ReviewStep from "./adLoadDrawer/ReviewStep";

const stepComponent = {
  select: <SelectStep />,
  productSets: <AssignProductSetsStep />,
  destinations: <AssignDestsStep />,
  review: <ReviewStep />,
  load: <LoadStep />,
} satisfies Record<AdLoadStep, ReactNode>;

type AdLoadDrawerProps = {
  onClose?: () => void;
};

const AdLoadDrawer = ({ onClose }: AdLoadDrawerProps) => {
  const { closeSession } = useSessionParam();
  const { isLoading: isLoadingAds } = useAds();
  const { isLoading: isLoadingInstExps } = useFetchInstantExperiences();
  const { isLoadingCampaigns } = useSelectedAcctCampaigns();

  const { session, isLoadingSession } = useCurrentSession();
  const adLoadStep = session.data.step;

  const isLoading =
    isLoadingSession ||
    isLoadingAds ||
    isLoadingInstExps ||
    (isLoadingCampaigns && adLoadStep !== "select");

  const onDrawerClose = () => {
    onClose?.();
    closeSession();
  };

  return (
    <Drawer
      visible={!!adLoadStep}
      width={"calc(100vw - 48px)"}
      onClose={onDrawerClose}
      closable={false}
      destroyOnClose
      maskClosable={false}
      title={<AdLoadSteps />}
      footer={<AdLoadFooter onClose={onDrawerClose} />}
      footerStyle={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {isLoading ? <Spinner /> : stepComponent[adLoadStep]}
    </Drawer>
  );
};

export default AdLoadDrawer;
