import { useCallback, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./HelpMenu.module.scss";
interface IHelpMenuProps {
  faqTitles: string[];
  cmpTitles: string[];
  selectedTitle: string;
  lastUpdated: {
    month: string;
    year: string;
  };
}
interface IHelpMenuHandler {
  setSelectedTitle: (title: string) => void;
}

const HelpMenu: React.FC<IHelpMenuProps & IHelpMenuHandler> = ({
  faqTitles,
  cmpTitles,
  selectedTitle,
  lastUpdated,

  setSelectedTitle,
}) => {
  const location = useLocation();

  const focusedStyle = {
    fontWeight: 600,
    backgroundColor: "#E6F7FF",
  };

  const assignSelectedTitle = useCallback(() => {
    const titleFromPath = location.pathname
      .substring(location.pathname.lastIndexOf("/") + 1)
      .replace(/-/g, " ")
      .toLowerCase()
      .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());

    const allTitles = faqTitles.concat(cmpTitles);
    for (const title of allTitles) {
      if (title.includes(titleFromPath)) {
        setSelectedTitle(title);
        break;
      }
    }
  }, [cmpTitles, faqTitles, location.pathname, setSelectedTitle]);

  useEffect(() => {
    assignSelectedTitle();
  }, [assignSelectedTitle, location.pathname]);

  return (
    <div>
      <div className={styles.titleMenuCtn}>
        <span className={styles.menuTitle}>FAQ</span>
        {faqTitles.map(title => {
          if (title === "Store Manager: Becoming Account Manager") {
            return (
              <NavLink key={`${title}-menu`} to="store-manager">
                <button
                  style={title === selectedTitle ? focusedStyle : undefined}
                >
                  {title}
                </button>
              </NavLink>
            );
          }

          return (
            <NavLink
              key={`${title}-menu`}
              to={title.toLowerCase().replace(/ /g, "-")}
            >
              <button
                style={title === selectedTitle ? focusedStyle : undefined}
              >
                {title}
              </button>
            </NavLink>
          );
        })}
      </div>

      <div className={styles.titleMenuCtn}>
        <span className={styles.menuTitle}>Resources</span>
        {cmpTitles.map(title => (
          <NavLink
            key={`${title}-menu`}
            to={title.toLowerCase().slice(0, 6).replace(" ", "-")}
          >
            <button style={title === selectedTitle ? focusedStyle : undefined}>
              {title}
            </button>
          </NavLink>
        ))}
      </div>
      <span
        className={styles.menuTitle}
      >{`Last Edited ${lastUpdated.month}, ${lastUpdated.year}`}</span>
    </div>
  );
};

export default HelpMenu;
