import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import styles from "./HelpContent.module.scss";

interface HelpContentProps {
  faqFields: Array<any>;
  cmpFields: Array<any>;
}

const HelpContent: FC<HelpContentProps> = ({ faqFields, cmpFields }) => {
  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
        <p className={styles.contentParagraphs}>{children}</p>
      ),
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_6]: (node: any, children: any) => (
        <p className={styles.contentQuestions}>{children}</p>
      ),

      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <p className={styles.contentQuestions}>{children}</p>
      ),
    },
  };

  return (
    <Routes>
      {faqFields.map(({ title, content }) => {
        if (title === "Store Manager: Becoming Account Manager") {
          return (
            <Route
              key={title}
              path="help/store-manager"
              element={() => documentToReactComponents(content, options)}
            />
          );
        }

        return (
          <Route
            key={title}
            path={`help/${title.toLowerCase().replace(/ /g, "-")}`}
            element={() => documentToReactComponents(content, options)}
          />
        );
      })}

      {cmpFields.map(({ title, content }) => {
        return (
          <Route
            key={title}
            path={`help/${title.toLowerCase().slice(0, 6).replace(" ", "-")}`}
            element={() => documentToReactComponents(content, options)}
          />
        );
      })}
    </Routes>
  );
};

export default HelpContent;
